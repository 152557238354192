<template>
    <div class="configure-page">
        <div class="title">
            <span>商户配置</span>
        </div>
        <div>
            <el-form
                :model="configureData"
                ref="addGoodsForm"
                status-icon
                :rules="rules"
                label-width="100px"
            >
                <!-- <el-form-item label="打印机名称" prop="name">
                    <el-input v-model="configureData.name" size="medium" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="起送价格" prop="send_price">
                    <el-input-number
                        v-model="configureData.send_price"
                        :min="0"
                        :max="b0000"
                        size="medium"
                        autocomplete="off"
                    ></el-input-number>
                    <span>元</span>
                    <div class="note">单位为分，1元为100分</div>
                </el-form-item>
                <el-form-item label="配送费" prop="distribution_amount">
                    <el-input-number
                        v-model="configureData.distribution_amount"
                        :min="0"
                        :max="1000000"
                        size="medium"
                        autocomplete="off"
                    ></el-input-number>
                    <span>元</span>
                    <div class="note">单位为分，1元为100分</div>
                </el-form-item>
                <el-form-item label="打包费" prop="packing_charge">
                    <el-input-number
                        v-model="configureData.packing_charge"
                        :min="0"
                        :max="b0000"
                        size="medium"
                        autocomplete="off"
                    ></el-input-number>
                    <span>元</span>
                    <div class="note">单位为分，1元为100分</div>
                </el-form-item>
                <!-- <el-form-item label="营业时间" prop="date">
                    <el-time-select size="medium" style="width: 160px"
                        placeholder="起始时间"
                        v-model="configureData.start_time"
                        :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00'
                        }">
                    </el-time-select>
                    ~
                    <el-time-select size="medium" style="width: 160px"
                        placeholder="结束时间"
                        v-model="configureData.end_time"
                        :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00',
                        minTime: configureData.start_time
                        }">
                    </el-time-select>
                </el-form-item> -->
                <el-form-item label="营业时间">
                    <div
                        v-for="(item, index) in businessTime"
                        :key="index"
                        :style="`margin-top: ${index === 1 ? '12px' : ''}`"
                    >
                        <el-time-select
                            class="select-time"
                            v-model="item.start_time"
                            :picker-options="{
                                start: '00:00',
                                step: '00:30',
                                end: '23:30',
                                minTime:
                                    index === 0 ? '' : businessTime[0].end_time,
                            }"
                            placeholder="选择时间"
                        >
                        </el-time-select>
                        ~
                        <el-time-select
                            class="select-time"
                            v-model="item.end_time"
                            :picker-options="{
                                start: '00:00',
                                step: '00:30',
                                end: '24:00',
                                minTime: '',
                            }"
                            placeholder="选择时间"
                        >
                        </el-time-select>
                        <el-button
                            style="margin-left: 12px"
                            v-if="businessTime.length < 2"
                            @click="addTime"
                            size="small"
                            >添加</el-button
                        >
                        <el-button
                            style="margin-left: 12px"
                            v-else
                            type="danger"
                            @click="deleteTime(index)"
                            size="small"
                            >删除</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item label="配送方式" prop="distribution_mode">
                    <el-select
                        v-model="configureData.distribution_mode"
                        size="medium"
                        placeholder="请选择"
                    >
                        <el-option label="飞鱼配送" :value="'飞鱼配送'">
                        </el-option>
                        <el-option label="自配送" :value="'自配送'">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商户状态" prop="distribution_mode">
                    <el-select
                        v-model="configureData.merchants_state"
                        size="medium"
                        placeholder="请选择商户状态"
                    >
                        <el-option label="开店" :value="'开店'"> </el-option>
                        <el-option label="关店" :value="'关店'"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                    <el-input
                        v-model="configureData.phone"
                        size="medium"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div style="padding: 14px 80px">
                <el-button
                    type="primary"
                    size="small"
                    @click="submitPrinter('addGoodsForm')"
                >
                    保 存
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
export default {
    name: "Printer",
    data() {
        return {
            // 验证规则
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入起送价",
                        trigger: "change",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入电话",
                        trigger: "change",
                    },
                ],
                distribution_mode: [
                    {
                        required: true,
                        message: "请选择配送方式",
                        trigger: "change",
                    },
                ],
            },

            // 增加/编辑商品业务数据
            configureData: {
                shipping_fee: 0,
                send_price: 0,
                distribution_amount: 0,
                distribution_mode: "飞鱼配送",
                start_time: "",
                phone: "",
                merchants_state: "开店",
                packing_charge: 0
            },
            businessTime: [
                {
                    start_time: "",
                    end_time: "",
                },
            ],
        };
    },
    mounted() {
        this.getPrinterData();
    },
    methods: {
        addTime() {
            this.businessTime.push({
                start_time: "",
                end_time: "",
            });
        },

        /**
         * 删除时间段
         * @param index 时间段标识
         */
        deleteTime(index) {
            this.businessTime.splice(index, 1);
        },

        // 获取配置
        getPrinterData() {
            this.axios.post("/config/get").then((res) => {
                this.configureData = { ...res.data };
                this.configureData.distribution_mode =
                    res.data.distribution_mode || "飞鱼配送";
                this.configureData.merchants_state =
                    res.data.merchants_state || "开店";
                console.log(this.configureData.distribution_mode);
                if (res.data.start_time.startsWith("[{")) {
                    this.businessTime = JSON.parse(res.data.start_time);
                } else {
                    this.businessTime[0].start_time = res.data.start_time;
                    this.businessTime[0].end_time = res.data.end_time;
                }
            });
        },

        submitPrinter(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let timeTrue = true;
                    if (this.businessTime.length === 1) {
                        if (
                            this.businessTime[0].start_time &&
                            this.businessTime[0].end_time
                        ) {
                        } else {
                            timeTrue = false;
                        }
                    }
                    if (this.businessTime.length === 2) {
                        if (
                            this.businessTime[0].start_time &&
                            this.businessTime[0].end_time &&
                            this.businessTime[1].start_time &&
                            this.businessTime[1].end_time
                        ) {
                            const startTimeStr1 =
                                "2000-01-01 " + this.businessTime[0].start_time;
                            const endTimeStr1 =
                                "2000-01-01 " + this.businessTime[0].end_time;
                            const startTimeStr2 =
                                "2000-01-01 " + this.businessTime[1].start_time;
                            const endTimeStr2 =
                                "2000-01-01 " + this.businessTime[1].end_time;
                            const startStamp1 = new Date(
                                startTimeStr1
                            ).getTime();
                            const endStamp1 = new Date(endTimeStr1).getTime();
                            const startStamp2 = new Date(
                                startTimeStr2
                            ).getTime();
                            const endStamp2 = new Date(endTimeStr2).getTime();

                            if (endStamp1 >= startStamp2) {
                                timeTrue = false;
                            }
                        } else {
                            timeTrue = false;
                        }
                    }

                    if (!timeTrue) {
                        this.$message.error("营业时间选择有误，请重新选择！");
                        return;
                    }
                    this.configureData.start_time = JSON.stringify(
                        this.businessTime
                    );
                    this.axios
                        .post("/config/save", this.configureData)
                        .then((res) => {
                            this.$message.success("保存成功");
                        })
                        .catch(() => {});
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.configure-page {
    .title {
        padding: 14px;
        margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }

    .el-input,
    .el-input-number {
        width: 260px;
    }

    .note {
        line-height: 20px;
        font-size: 14px;
        color: #d84040;
    }
}
</style>
